<template>
    <div class="wraper" v-if="detail">
        <Header :isShow="isShow" />
        <!-- <div class="banner">
            <img :src="imgUrl + banner" alt="" />
        </div> -->
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box">
                <p >{{detail.title}}</p>
            </div>
            <p class="time">发布时间：{{detail.releasetime.split(' ')[0]}}</p>
            <div class="line"></div>
            <div class="detail max_small_box"  v-html="detail.detail">
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {

    //轮播图
    const banner = ref()

    //详情
    const detail = ref()

     //获取详情
    const getDetail = async (id) => {
        let postData = {
            id: id,
        }
        try{
            const result = await post('/resource/get_resource_detail', postData)
            // console.log('教育基地详情', result)
            if(result.code == 1){
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    } 

    //获取轮播图
    const getBanner = async () => {
        let postData = {
            page_id: '/resource'
        }
        await post('/carousel/get_carousel', postData).then((res) => {
            // console.log('11轮播图', res)
            if(res.code == 1) {
                banner.value = res.data.images
            }
        })
    }

    return { detail, getDetail, getBanner, banner }
}

export default {
    name: 'ResourcesEducationDetail',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail, getBanner, banner } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        getBanner()

        onMounted(() => {
            getDetail(route.params.id)  //获取详情
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, banner }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 114px;
    @media screen and (max-width: 1200px){
        margin-top: 92px;
    }
    img{
        width: 100%;
        display: block;
    }
}
.content{
    margin-top: 50px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    @media screen and (max-width: 900px){
        margin-top: 30px;
    }
    .title{
        text-align: center;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 36px;
            font-weight: 600;
            line-height: 60px;
            @media screen and (max-width: 900px){
                font-size: .18rem;
                line-height: 22px;
            }
        }
    }
    .time{
        color: $all-desc-color;
        font-size: 14px;
        margin-top: 30px;
        text-align: center;
        @media screen and (max-width: 900px){
            font-size: .14rem;
            margin-top: 15px;
        }
    }
    .line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        margin-top: 30px;
        @media screen and (max-width: 900px){
            margin: 30px 30px 0 30px;
        }
    }
}

</style>